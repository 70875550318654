import axios, { AxiosError, AxiosInstance, Method } from "axios";
import fileDownload from "js-file-download";
import { TenantId } from "../App";
import { apiConfig } from "../config/api";
import { getAccessToken } from "./firebase";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/browser";

export const pageLimit = 10;
export const smallPageLimit = 5;

const dateTimeReviver = (key, value) => {
  if (key === "createdAtUtc" || key === "updatedAtUtc") {
    return new Date(value);
  }
  return value;
};

axios.defaults.transformResponse = [
  function transformResponse(data, headers) {
    // Optionally you can check the response headers['content-type'] for application/json or text/json
    return JSON.parse(data, dateTimeReviver);
  },
];

export interface Result<T> {
  data: T | null;
  statusCode: number | null;
  error: any | null;
}

export interface RequestOptions {
  method: Method;
  path: string;
  params?: any;
  body?: any;
  headers?: any;
  useProcessor?: boolean;
  retryAttempts?: number;
}

export async function makeRequest<T>(
  options: RequestOptions
): Promise<T | null> {
  const accessToken = await getAccessToken(TenantId);
  if (accessToken == null) {
    return null;
  }

  const res = await makeRequestWrapResult<T>(options);
  if (res.error) {
    throw res.error;
  }

  return res.data;
}

export async function makeRequestWrapResult<T>(
  options: RequestOptions
): Promise<Result<T>> {
  if (options.method === "GET" && options.body) {
    return {
      data: null,
      error: new Error("cannot send body with get"),
      statusCode: null,
    };
  }

  const accessToken = await getAccessToken(TenantId);
  if (accessToken == null) {
    return { data: null, error: new Error("missing auth"), statusCode: null };
  }

  const baseUrl = options.useProcessor
    ? apiConfig.processorBaseUrl
    : apiConfig.baseUrl;

  try {
    const res = await axiosClient(options).request({
      url: `${baseUrl}${options.path}`,
      method: options.method,
      timeout: 60000 * 15 - 1,
      headers: {
        ...(options.headers || {}),
        Authorization: `Bearer ${accessToken}`,
      },
      params: options.params,
      data: options.body,
    });

    return { data: res.data, statusCode: res.status, error: null };
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
    if (axios.isAxiosError(err)) {
      console.log(err.toJSON());
      console.error(err.response?.data);
      console.error(err.response?.status);
      Sentry.captureEvent({
        message: "API Error",
        extra: {
          code: err.code,
          cause: err.cause,
          data: err.response?.data,
          status: err.response?.status,
        },
      });
      return {
        data: err.response?.data,
        error: err,
        statusCode: err.response?.status || null,
      };
    }

    return { data: null, error: err, statusCode: null };
  }
}

export async function makeRequestNoAuth<T>(
  options: RequestOptions
): Promise<T | null> {
  if (options.method === "GET" && options.body) {
    throw new Error("cannot send body with get");
  }

  const res = await axiosClient(options).request({
    url: `${apiConfig.baseUrl}${options.path}`,
    method: options.method,
    params: options.params,
    data: options.body,
  });

  return res.data;
}

function axiosClient(options: RequestOptions): AxiosInstance {
  const client = axios.create({});
  if (options.retryAttempts) {
    axiosRetry(client, {
      retries: options.retryAttempts,
      retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 500; // time interval between retries
      },
      retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return !error.response || error.response.status >= 500;
      },
    });
  }

  return client;
}
export interface PaginatedInfo {
  count: number;
  pageTotal: number;
  pageCurrent: number;
  pageSize: number;
}

export interface PaginatedResponse<T> {
  count: number;
  pageTotal: number;
  pageCurrent: number;
  pageSize: number;
  results: T[];
}

export interface SuccessResponse {
  message: string;
}

import {
  makeRequest,
  pageLimit,
  PaginatedResponse,
  SuccessResponse,
} from "./client";
import { Role } from "./roles-client";
import * as Sentry from "@sentry/react";

export async function getMe(): Promise<UserWithRoles | null> {
  const user = await makeRequest<UserWithRoles>({
    method: "GET",
    path: "/api/users/me",
  });
  if (user) {
    Sentry.setUser({
      id: user.userId || user.email,
      email: user.email,
      username: user.firstName + " " + user.lastName,
      // Optional additional data
      ipAddress: "{{auto}}", // Let Sentry automatically capture IP
    });

    return user;
  }

  return user;
}

export async function getUsers(
  offset: number,
  limit: number = pageLimit
): Promise<PaginatedResponse<User> | null> {
  return makeRequest<PaginatedResponse<User>>({
    method: "GET",
    path: "/api/users",
    params: {
      limit: limit,
      offset: offset * limit,
    },
  });
}

export async function getUser(id: string): Promise<User | null> {
  return makeRequest<User>({
    method: "GET",
    path: `/api/users/${id}`,
  });
}

export async function deleteUser(id: string): Promise<SuccessResponse | null> {
  return makeRequest<SuccessResponse>({
    method: "DELETE",
    path: `/api/users/${id}`,
  });
}

export async function createUser(
  user: CreateUserRequest
): Promise<User | null> {
  return makeRequest<User>({
    method: "POST",
    path: "/api/users",
    body: user,
  });
}

export async function updateUser(user: User): Promise<User | null> {
  return makeRequest<User>({
    method: "PUT",
    path: `/api/users/${user.userId}`,
    body: user,
  });
}

export async function resetUserPassword(id: string): Promise<User | null> {
  return makeRequest<User>({
    method: "POST",
    path: `/api/users/${id}/reset_password`,
  });
}

export interface UserPermissions {
  roleCount: number;
  canReadConnections: boolean;
  canReadDataTables: boolean;
  canReadUsers: boolean;
  canReadRoles: boolean;
  canUpload: boolean;
}

export interface User {
  userId: string | null;
  email: string;
  firstName: string;
  lastName: string;
  permissions: UserPermissions | null;
  owner: boolean;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}

export interface CreateUserRequest extends User {
  roles: { roleId: string }[];
}

export interface UserWithRoles extends User {
  roles: Role[];
}

export enum RoleEntityType {
  Connection = "connection",
  DataTable = "dataTable",
  DataTableUpload = "dataTableUpload",
  User = "user",
}

import {
  makeRequest,
  pageLimit,
  PaginatedResponse,
  SuccessResponse,
} from "./client";
import { RoleEntityType, UserPermissions } from "./users-client";

export async function getRole(id: string): Promise<Role | null> {
  return makeRequest<Role>({
    method: "GET",
    path: `/api/roles/${id}`,
  });
}

export async function getRoles(
  offset: number,
  limit: number = pageLimit
): Promise<PaginatedResponse<Role> | null> {
  return makeRequest<PaginatedResponse<Role>>({
    method: "GET",
    path: "/api/roles",
    params: {
      limit: limit,
      offset: offset * limit,
    },
  });
}

export async function getSimpleRoles(): Promise<SimpleRole[] | null> {
  return makeRequest<SimpleRole[]>({
    method: "GET",
    path: "/api/roles/simple",
  });
}

export async function createRole(role: Role): Promise<Role | null> {
  return makeRequest<Role>({
    method: "POST",
    path: "/api/roles",
    body: role,
  });
}

export async function updateRole(role: Role): Promise<Role | null> {
  return makeRequest<Role>({
    method: "PUT",
    path: `/api/roles/${role.roleId}`,
    body: role,
  });
}

export async function deleteRole(id: string): Promise<SuccessResponse | null> {
  return makeRequest<SuccessResponse>({
    method: "DELETE",
    path: `/api/roles/${id}`,
  });
}

export interface Permission {
  entityType: RoleEntityType;
  entityId: string | null;
  permissions: string;
}
export interface RolePermission {
  roleId: string;
  entityType: RoleEntityType;
  entityId: string | null;
  permissions: string;
}

export interface Role {
  roleId: string;
  name: string;
  connectionPermissions: Array<RolePermission>;
  dataTablePermissions: Array<RolePermission>;
  dataTableUploadPermissions: Array<RolePermission>;
  userPermissions: Array<RolePermission>;
  userRoles: Array<UserRole>;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}

export interface UserRole {
  roleId: string;
  userId: string;
}

export interface SimpleRole {
  roleId: string;
  name: string;
}

import axios from "axios";
import {
  makeRequest,
  makeRequestWrapResult,
  pageLimit,
  PaginatedResponse,
  Result,
  smallPageLimit,
  SuccessResponse,
} from "./client";
import {
  DataTable,
  DataTableUploadEntry,
  DataType,
  OutputType,
} from "./data-tables-client";
import { User } from "./users-client";
import JSZip from "jszip";

export async function createDataTableUpload(
  dataTableUpload: DataTableUpload
): Promise<Result<DataTableUpload>> {
  return makeRequestWrapResult<DataTableUpload>({
    method: "POST",
    path: "/api/datatableuploads",
    body: dataTableUpload,
  });
}

export async function getDataTableUploads(
  offset: number,
  limit: number = pageLimit
): Promise<PaginatedResponse<DataTableUpload> | null> {
  return makeRequest<PaginatedResponse<DataTableUpload>>({
    method: "GET",
    path: "/api/datatableuploads",
    params: {
      limit: limit,
      offset: offset * limit,
    },
  });
}

export async function createUploadUrls(
  fileName: string,
  fileSize: number
): Promise<CreateUploadUrlResponse | null> {
  return await makeRequest<CreateUploadUrlResponse>({
    method: "POST",
    path: "/api/datatableuploads/create_upload_urls",
    body: {
      fileName: fileName,
      fileType: "application/zip",
      fileSize: fileSize,
    },
  });
}

export async function zipFile(file: File): Promise<Blob> {
  if (file.type === "application/zip" || file.name.endsWith(".zip")) {
    return file;
  }

  const zip = new JSZip();
  zip.file(file.name, file);
  const zippedBlob = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 9,
    },
  });

  console.log(
    "Zipping completed, file sizes: before %d, after %d",
    file.size,
    zippedBlob.size
  );

  return zippedBlob;
}

export async function uploadFile(
  presignedUrls: string[],
  file: Blob | File,
  chunks: number[],
  incrementUploadProgress: (progress: number) => void
) {
  let chunkStart = 0;
  const reqs: Promise<string>[] = [];
  const trackedProgresses = {};

  for (let i = 0; i < presignedUrls.length; i++) {
    const presignedUrl = presignedUrls[i];
    const chunk = chunks[i];
    const chunkSizePercentage = chunk / file.size;

    reqs.push(
      axios
        .put(presignedUrl, file.slice(chunkStart, chunkStart + chunk), {
          headers: {
            "Content-Type": "application/zip",
          },
          responseType: "text",
          transformResponse: (res) => {
            return res;
          },
          transformRequest: (data, headers) => {
            Object.keys(headers)
              .filter(
                (key) =>
                  key.toLowerCase() !== "content-type" &&
                  key.toLowerCase() !== "content-length"
              )
              .forEach((key) => {
                delete headers[key];
              });
            return data;
          },
          onUploadProgress: (event) => {
            if (event.lengthComputable && event.total) {
              const newPercentage = Math.round(
                (event.loaded / event.total) * 100 * chunkSizePercentage
              );
              const lastPercentage = trackedProgresses[i] || 0;
              trackedProgresses[i] = newPercentage;
              const diff = newPercentage - lastPercentage;
              if (diff > 0) {
                // console.log(`
                // -----
                // ${newPercentage}
                // ${lastPercentage}
                // ${diff}
                // ${(event.loaded / event.total) * 100}
                // ${chunkSizePercentage}
                // ${i}
                // -----
                // `);
                incrementUploadProgress(diff);
              }
            }
          },
        })
        .then((resp) => {
          return resp.headers["etag"]!!;
        })
    );

    chunkStart += chunk;
  }

  return await Promise.all(reqs);
}

export async function readUpload(
  key: string,
  fileName: string,
  isNewDataTable: boolean,
  externalUploadId: string,
  etags: string[]
): Promise<UploadData | null> {
  return await makeRequest<UploadData>({
    method: "POST",
    path: "/api/datatableuploads/read_upload",
    body: {
      fileName: fileName,
      key: key,
      isNewDataTable: isNewDataTable,
      externalUploadId: externalUploadId,
      etags: etags,
    },
    headers: {},
    useProcessor: true,
    retryAttempts: 3,
  });
}

export async function getUploadById(
  id: string
): Promise<DataTableUpload | null> {
  return makeRequest<DataTableUpload>({
    method: "GET",
    path: `/api/datatableuploads/${id}`,
  });
}

export async function getUploadRawDownloadLink(
  id: string
): Promise<DownloadLink | null> {
  return makeRequest<DownloadLink>({
    method: "GET",
    path: `/api/datatableuploads/${id}/download/raw`,
  });
}

export async function getUploadProcessedDownloadLink(
  id: string
): Promise<DownloadLink | null> {
  return makeRequest<DownloadLink>({
    method: "GET",
    path: `/api/datatableuploads/${id}/download/processed`,
  });
}

export async function importUpload(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest<SuccessResponse>({
    method: "POST",
    path: `/api/datatableuploads/${id}/request_import`,
    retryAttempts: 3,
  });
}

export async function validateUpload(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest<SuccessResponse>({
    method: "POST",
    path: `/api/datatableuploads/${id}/request_validate`,
    retryAttempts: 3,
  });
}

export async function getUploadEntries(
  id: string,
  failedOnly: boolean | null,
  page: number,
  limit: number = smallPageLimit
): Promise<PaginatedResponse<DataTableUploadEntry> | null> {
  const params = {
    limit: limit,
    offset: page * limit,
  };
  if (failedOnly != null) {
    params["failed_only"] = `${failedOnly}`;
  }

  return makeRequest<PaginatedResponse<DataTableUploadEntry>>({
    method: "GET",
    path: `/api/datatableuploads/${id}/entries`,
    params: params,
  });
}

export async function updateUploadEntries(
  id: string,
  updatedReqs: UpdateEntryRequest[]
): Promise<PaginatedResponse<DataTableUploadEntry> | null> {
  return makeRequest<PaginatedResponse<DataTableUploadEntry>>({
    method: "POST",
    path: `/api/datatableuploads/${id}/entries`,
    body: updatedReqs,
  });
}

export async function abandonUpload(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest<SuccessResponse>({
    method: "POST",
    path: `/api/datatableuploads/${id}/abandon`,
    retryAttempts: 3,
  });
}

export interface DownloadLink {
  url: string;
}

export interface UpdateEntryRequest {
  entryId: string;
  value: { [key: string]: any };
}

export enum DataTableUploadStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PENDING_EXPORT = "PENDING_EXPORT",
  EXPORTING = "EXPORTING",
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  REVERTED = "REVERTED",
  CANCELLED = "CANCELLED",
  IMPORTED = "IMPORTED",
}

export enum DataTableUploadMode {
  APPEND = "APPEND",
  REPLACE = "REPLACE",
}

export interface DataTableUpload {
  dataTableUploadId: string | null;
  fileName: string;
  fileKey: string;
  dataTableId: string;
  dataTable: DataTable | null;
  uploadUserId?: string;
  uploadUser?: User | null;
  status: DataTableUploadStatus;
  uploadMode: DataTableUploadMode;
  failureMessage: string | null;
  rowCount: number;
  rowFailureCount: number;
  failureCount: number;
  progress: UploadProgress | null;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}
export interface UploadProgress {
  step: number;
  stepName: string;
  percentage: number;
}

export interface CreateUploadUrlResponse {
  uploadUrls: string[];
  key: string;
  externalUploadId: string;
  chunks: number[];
}

export interface UploadData {
  key: string;
  rows: any[];
  typeByColumn: {
    [key: string]: DataType;
  };
  indexByColumn: {
    [key: string]: number;
  };
  tables: DataTable[];
}

export function parseUploadEntryValidationErrors(errors: string[] | null): {
  [key: string]: string[];
} {
  return (errors || []).reduce((obj, err) => {
    err
      .substring(err.lastIndexOf("[") + 1, err.lastIndexOf("]"))
      .split(",")
      .forEach((col) => {
        obj[col.toLowerCase()] = (obj[col.toLowerCase()] || []).concat(err);
      });

    return obj;
  }, {});
}

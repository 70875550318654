function genBaseUrls() {
  if (window.location.href.indexOf("localhost") !== -1)
    // return "http://localhost:8080";
    return [
      "https://api-staging.referencr.com",
      "https://processor-staging.referencr.com",
    ];
  if (window.location.href.indexOf("app.referencr.com") !== -1)
    return [
      "https://api-staging.referencr.com",
      "https://processor-staging.referencr.com",
    ];
  if (window.location.href.indexOf("test.referencr.com") !== -1)
    return [
      "https://api-staging.referencr.com",
      "https://processor-staging.referencr.com",
    ];
  if (window.location.href.indexOf("demo.referencr.com") !== -1)
    return [
      "https://api-staging.referencr.com",
      "https://processor-staging.referencr.com",
    ];
  if (window.location.href.indexOf("1test1") !== -1)
    return [
      "https://api-staging.referencr.com",
      "https://processor-staging.referencr.com",
    ];

  return ["https://api.referencr.com", "https://processor.referencr.com"];
}

export const apiConfig = {
  baseUrl: genBaseUrls()[0],
  processorBaseUrl: genBaseUrls()[1],
};

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import "./index.css";
import "react-dropzone-uploader/dist/styles.css";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { initializeApp } from "firebase/app";
import firebaseConfig from "./config/firebaseConfig";
import { hotjar } from "react-hotjar";
import "@fontsource/inter"; // Defaults to weight 400.
import * as Sentry from "@sentry/react";
import { apiConfig } from "./config/api";

console.log(apiConfig.baseUrl);
Sentry.init({
  dsn: "https://16b3046baefe1fb17bce79e28db18bf9@o4507978551787520.ingest.de.sentry.io/4507978553229392",
  integrations: [
    ...(apiConfig.baseUrl === "https://api.referencr.com"
      ? [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            networkDetailAllowUrls: [
              "https://api.referencr.com/.*",
              "https://processor.referencr.com/.*",
              "https://processor.referencr.com/api/datatableuploads/read_upload",
            ],
            networkRequestHeaders: [
              "access-control-request-headers",
              "access-control-request-method",
              "origin",
              "referer",
              "sec-fetch-mode",
              "sec-fetch-site",
              "sec-fetch-dest",
              "user-agent",
            ],
            networkResponseHeaders: [
              "Access-Control-Allow-Origin",
              "Access-Control-Allow-Methods",
              "Access-Control-Allow-Headers",
              "Access-Control-Allow-Credentials",
              "Access-Control-Expose-Headers",
              "Access-Control-Max-Age",
              "Content-Type",
              "trace-id",
            ],
            networkCaptureBodies: true,
          }),
        ]
      : []),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api\.referencr\.com/,
    /^https:\/\/api-staging\.referencr\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate:
    apiConfig.baseUrl === "https://api.referencr.com" ? 0.05 : 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate:
    apiConfig.baseUrl === "https://api.referencr.com" ? 1.0 : 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// @ts-ignore
const firebaseApp = initializeApp(firebaseConfig);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const colors = {
  primary: "#4F44E0",
  primaryScheme: {
    50: "#e9e8ff",
    100: "#c0bcf9",
    200: "#9892ef",
    300: "#6f66e7",
    400: "#463ade",
    500: "#2c21c5",
    600: "#21199a",
    700: "#17116f",
    800: "#0c0a45",
    900: "#04031d",
  },
  secondary: "#38309C",
  dark: "#241F5F",
  grey: "#F6F6F6",
  darkGrey: "#707070",
  white: "#FFFFFF",
  light: "#6D66C9",
  action: "#4F44E0",
};

const fonts = {
  body: "Inter, system-ui, sans-serif",
  heading: "Inter, system-ui, sans-serif",
};
const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "3rem",
  "6xl": "3.75rem",
  "7xl": "4.5rem",
  "8xl": "6rem",
  "9xl": "8rem",
};
const theme = extendTheme({ colors, fonts, fontWeights, fontSizes });

hotjar.initialize({
  id: 2982750,
  sv: 6,
});

root.render(
  <QueryClientProvider client={queryClient}>
    <HashRouter>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </HashRouter>
  </QueryClientProvider>
);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "../config/firebaseConfig";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  User,
  FacebookAuthProvider,
  UserCredential,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { getAccessTokenCookie, setAccessTokenCookie } from "./cookies";
import { getMe } from "./users-client";
import { User as AppUser } from "./users-client";
import { getTenantId } from "./tenant-client";

const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);

async function getAccessToken(tenantId: string | null): Promise<string | null> {
  const auth = getAuth();
  auth.tenantId = tenantId || (await getTenantId());

  const token = (await auth.currentUser?.getIdToken()) || null;
  if (token != null) {
    setAccessTokenCookie(token);
  }

  if (token == null) {
    return getAccessTokenCookie();
  }

  return token;
}

function useFirebaseAuth(tenantId: string) {
  const auth = getAuth();
  auth.tenantId = tenantId;

  const googleSignIn = new GoogleAuthProvider();
  const facebookSignIn = new FacebookAuthProvider();

  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [user, setUser] = useState<AppUser | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const clear = () => {
    setAccessTokenCookie(null);
    setUser(null);
    setLoading(false);
    setAccessToken(null);
  };

  const authStateChanged = async (user: User | null) => {
    if (!user) {
      setAccessTokenCookie(null);
      setUser(null);
      setLoading(false);
      setFirstLoad(true);
      return;
    }

    const token = await user.getIdToken();
    if (token != null) {
      setAccessTokenCookie(token);
      setAccessToken(token);
    }

    try {
      const fetchedUser = await getMe();
      setUser(fetchedUser);

      setLoading(false);
      setFirstLoad(true);
    } catch (e) {
      console.log(e);
      signOut();
    }
  };

  const signInWithEmail = (email: string) => {
    setLoading(true);
    signInWithEmailLink(auth, email);
  };

  const signInWithGoogle = () => {
    setLoading(true);
    signInWithRedirect(auth, googleSignIn);
  };

  const signInWithFacebook = () => {
    setLoading(true);
    signInWithRedirect(auth, facebookSignIn);
  };

  const signInWithPassword = (
    email: string,
    password: string
  ): Promise<UserCredential> => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password).finally(() =>
      setLoading(false)
    );
  };
  const signOut = () => {
    setLoading(true);
    auth
      .signOut()
      .then(() => clear())
      .then(() => window.location.reload());
  };

  useEffect(
    () => {
      const unsubscribe = auth.onAuthStateChanged(authStateChanged);
      return () => unsubscribe();
    },
    // eslint-disable-next-line
    [auth]
  );

  return {
    loading,
    signInWithEmail,
    signInWithGoogle,
    signInWithFacebook,
    signInWithPassword,
    signOut,
    user,
    accessToken,
    firstLoad,
  };
}

export { firebase, analytics, useFirebaseAuth, getAccessToken };

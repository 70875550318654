import {
  Button,
  Checkbox,
  CreateToastFnReturn,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Select,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import classNames from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { FaPlus } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Connection, getConnections } from "../api/connections-client";
import { DataTableUploadMode } from "../api/data-table-uploads-client";
import {
  createDataTable,
  DataTable,
  DataTableColumn,
  DataType,
  getDataTable,
  OutputType,
  updateDataTable,
  deleteDataTable,
  OutputMode,
  generateDataTableScript,
} from "../api/data-tables-client";
import {
  getMe,
  RoleEntityType,
  User,
  UserWithRoles,
} from "../api/users-client";
import SimpleTable, {
  TableColumn,
  TableColumnDataType,
} from "../components/SimpleTable";
import UploadDropzone from "../components/UploadDropzone";
import { useSaveDataTableMutation } from "../mutate/data-tables";
import { createUpload } from "../mutate/uploads";
import { uuidv4 } from "../util/uuid";

import styles from "./MutateDataSource.module.scss";
import { DeleteSimpleDialog } from "../components/SimpleDialog";
import {
  MdFunctions,
  MdDelete,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from "react-icons/md";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { canDelete, canUpload, canWrite } from "../util/permission";
import ScriptEditor from "../components/ScriptEditor";
import { useUploadService } from "../services/upload-service";

function renderCustomColumns(
  row: any,
  column: TableColumn,
  value: any,
  onChange: (value: any) => void | null
): ReactNode | null {
  if (column.id === "dataType") {
    return (
      <Select
        id={`dataType-${row.name}`}
        data-testid={`dataType-${row.name}`}
        className={styles.dataTypeSelect}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value={DataType.TEXT}>Text</option>
        <option value={DataType.INTEGER}>Integer</option>
        <option value={DataType.DECIMAL}>Decimal</option>
        <option value={DataType.DATE}>Date</option>
        <option value={DataType.DATETIME}>DateTime</option>
      </Select>
    );
  }
  if (column.id === "aliases") {
    return (
      <Input
        id={`aliases-${row.name}`}
        data-testid={`aliases-${row.name}`}
        className={styles.input}
        onChange={(e) => onChange(e.target.value.split(","))}
        value={value.join(",")}
      />
    );
  }
  if (column.id === "isKey") {
    return (
      <Checkbox
        id={`isKey-${row.name}`}
        data-testid={`isKey-${row.name}`}
        disabled={row.isUnique}
        className={styles.input}
        onChange={(e) => onChange && onChange(e.target.checked)}
        isChecked={value}
      />
    );
  }
  if (column.id === "isUnique") {
    return (
      <Checkbox
        id={`isUnique-${row.name}`}
        data-testid={`isUnique-${row.name}`}
        disabled={row.isKey}
        className={styles.input}
        onChange={(e) => onChange && onChange(e.target.checked)}
        isChecked={value}
      />
    );
  }

  return null;
}

function renderCreateFromUpload(
  user: UserWithRoles | null,
  newUploadKey: string | null,
  newUploadFileRows: any[],
  columns: DataTableColumn[],
  appendOnly: boolean,
  scriptEditorColumn: DataTableColumn | null,
  setColumns: (cols: DataTableColumn[]) => void,
  setNewUploadFile: (f: File) => void,
  setLoadingFile: (b: boolean) => void,
  setNewUploadFileRows: (rows: any[]) => void,
  setNewUploadKey: (k: string | null) => void,
  setNewUploadFileColumnTypes: (types: { [key: string]: DataType }) => void,
  setScriptEditorColumn: (c: DataTableColumn | null) => void,
  onUploadFail: () => void
) {
  return (
    <>
      {newUploadKey == null && (
        <UploadDropzone
          canUpload={canUpload(
            user,
            RoleEntityType.DataTable,
            newUploadKey || ""
          )}
          setStepName={() => {}}
          user={user || null}
          setNewUploadDataTable={() => {}}
          setNewUploadFile={setNewUploadFile}
          setLoadingFile={setLoadingFile}
          incrementUploadProgress={() => {}}
          setNewUploadFileColumnTypes={(colTypes, indexes) => {
            setColumns(
              Object.keys(colTypes)
                .map((colName) => ({
                  dataTableColumnId: uuidv4(),
                  dataTableId: uuidv4(),
                  name: colName,
                  dataType: colTypes[colName],
                  index: indexes[colName],
                  isKey: false,
                  isUnique: false,
                  isRequired: false,
                  allowedValues: null,
                  customValue: "",
                  aliases: [],
                  script: null,
                  createdAtUtc: new Date(),
                  updatedAtUtc: new Date(),
                }))
                .sort((a, b) => a.index - b.index)
            );
            setNewUploadFileColumnTypes(colTypes);
          }}
          setNewUploadFileRows={(rows: any[]) => {
            setNewUploadFileRows(rows);
          }}
          setNewUploadKey={setNewUploadKey}
          setNewUploadDataTables={() => {}}
          onFail={onUploadFail}
          isNewDataTable={true}
        />
      )}
      {newUploadKey != null &&
        renderConfigureColumnsTable(
          user || null,
          columns,
          appendOnly,
          scriptEditorColumn,
          setColumns,
          newUploadFileRows,
          setScriptEditorColumn
        )}
    </>
  );
}

function renderConfigureColumnsTable(
  user: UserWithRoles | null,
  columns: DataTableColumn[],
  appendOnly: boolean,
  scriptEditorColumn: DataTableColumn | null,
  setColumns: (cols: DataTableColumn[]) => void,
  dataSamples: any[] | null,
  setScriptEditorColumn: (c: DataTableColumn | null) => void
) {
  const headers = [
    {
      id: "name",
      name: "Column Name",
      dataType: TableColumnDataType.Text,
    },
    {
      id: "aliases",
      name: "Column Aliases",
      dataType: TableColumnDataType.Text,
    },
    ...(dataSamples != null
      ? [
          {
            id: "dataSample",
            name: "Data Sample",
            dataType: TableColumnDataType.Text,
          },
        ]
      : []),
    {
      id: "dataType",
      name: "Data Type",
      dataType: TableColumnDataType.Text,
      width: 160,
    },
    ...(appendOnly
      ? []
      : [
          {
            id: "isKey",
            name: "Key",
            dataType: TableColumnDataType.Boolean,
          },
        ]),
    {
      id: "isUnique",
      name: "Unique",
      dataType: TableColumnDataType.Boolean,
    },
    {
      id: "isRequired",
      name: "Required",
      dataType: TableColumnDataType.Boolean,
    },
  ];

  function rowActions(row) {
    const scriptEditorButton = (
      <Button
        className={styles.actionBtn}
        variant="outline"
        background={row.script ? "#2c21c5" : undefined}
        color={row.script ? "white" : undefined}
        onClick={() => setScriptEditorColumn(row)}
      >
        <Icon as={MdFunctions} boxSize={6} />
      </Button>
    );

    return (
      <div className={styles.actionsContainer}>
        {scriptEditorButton}
        <Button
          className={styles.actionBtn}
          variant="outline"
          onClick={() =>
            setColumns(
              columns.filter(
                (col) => col.dataTableColumnId !== row.dataTableColumnId
              )
            )
          }
        >
          <Icon as={MdDelete} boxSize={6} />
        </Button>
        <div className={styles.actionsArrowContainer}>
          {row.index > 0 && (
            <Button
              aria-label="Move row up"
              className={styles.actionArrow}
              variant="ghost"
              onClick={() => {
                if (row.index > 0 && row.index < columns.length) {
                  const copy = [...columns];
                  const currRow = copy[row.index];
                  const aboveRow = copy[row.index - 1];
                  copy[row.index] = copy[row.index - 1];
                  copy[row.index - 1] = currRow;
                  currRow.index -= 1;
                  aboveRow.index += 1;
                  setColumns(copy);
                }
              }}
            >
              <Icon as={MdKeyboardArrowUp} boxSize={6} />
            </Button>
          )}
          {row.index < columns.length - 1 && (
            <Button
              aria-label="Move row down"
              className={
                row.index === 0
                  ? classNames(styles.actionArrow, styles.firstRow)
                  : styles.actionArrow
              }
              variant="ghost"
              onClick={() => {
                if (row.index >= 0 && row.index < columns.length - 1) {
                  const copy = [...columns];
                  const currRow = copy[row.index];
                  const belowRow = copy[row.index + 1];
                  copy[row.index] = copy[row.index + 1];
                  copy[row.index + 1] = currRow;
                  currRow.index += 1;
                  belowRow.index -= 1;
                  setColumns(copy);
                }
              }}
            >
              <Icon as={MdKeyboardArrowDown} boxSize={6} />
            </Button>
          )}
        </div>
      </div>
    );
  }

  function getDataSample(dataSampleRow: any, col: DataTableColumn) {
    const colNameList = [col.name, ...col.aliases];
    for (let colN of colNameList) {
      if (dataSampleRow[colN] != null && dataSampleRow[colN] !== "") {
        return dataSampleRow[colN];
      }
    }
  }

  let formattedDataSample = dataSamples?.[0] || null;
  if (formattedDataSample) {
    formattedDataSample = Object.keys(formattedDataSample).reduce(
      (obj, key) => {
        const col = columns.find((c) => c.name === key);
        switch (col?.dataType) {
          case DataType.INTEGER:
            obj[key] = parseInt(formattedDataSample[key]);
            break;
          case DataType.DECIMAL:
            obj[key] = parseFloat(formattedDataSample[key]);
            break;
          case DataType.DATE:
          case DataType.ENUM:
          case DataType.TEXT:
          case DataType.DATETIME:
            obj[key] = formattedDataSample[key];
        }
        return obj;
      },
      {}
    );
  }

  return (
    <>
      {scriptEditorColumn != null && (
        <ScriptEditor
          dataSample={formattedDataSample}
          dataType={scriptEditorColumn.dataType}
          initialScript={scriptEditorColumn?.script || ""}
          header={scriptEditorColumn?.name || "Modify"}
          isOpen={scriptEditorColumn != null}
          onClose={(text: string | null, isDelete: boolean = false) => {
            if (text != null || isDelete) {
              setColumns(
                columns.map((col) => {
                  if (
                    col.dataTableColumnId ===
                    scriptEditorColumn?.dataTableColumnId
                  ) {
                    return {
                      ...col,
                      script: text,
                    };
                  } else {
                    return col;
                  }
                })
              );
            }
            setScriptEditorColumn(null);
          }}
          variables={columns.reduce(
            (obj, col) => ({
              ...obj,
              [col.name]: col.dataType,
            }),
            {}
          )}
        />
      )}
      <SimpleTable
        editable={true}
        className={styles.table}
        columns={headers}
        data={
          dataSamples != null
            ? columns.map((col) => ({
                ...col,
                dataSample: getDataSample(dataSamples[0], col),
              }))
            : columns
        }
        onChange={(_row: any, idx: number, column: TableColumn, value: any) => {
          const row = columns[idx];
          if (column.id === "isRequired" && row["isKey"]) {
            return;
          }
          let newRow = {
            ...row,
            [column.id]: value,
          };
          if (column.id === "isKey") {
            newRow = {
              ...newRow,
              isRequired: true,
            };
          }

          setColumns(
            columns.map((currRow, rowIdx) =>
              rowIdx === idx ? newRow : currRow
            )
          );
        }}
        readOnlyColumns={["dataSample"]}
        columnRenderer={renderCustomColumns}
        addBtn={
          canWrite(user, RoleEntityType.DataTable, "") && (
            <Flex
              onClick={() =>
                setColumns(
                  columns.concat([
                    {
                      dataTableColumnId: uuidv4(),
                      dataTableId: uuidv4(),
                      name: "",
                      index: columns.length,
                      dataType: DataType.TEXT,
                      isKey: false,
                      isUnique: false,
                      isRequired: false,
                      allowedValues: null,
                      customValue: "",
                      aliases: [],
                      script: null,
                      createdAtUtc: new Date(),
                      updatedAtUtc: new Date(),
                    },
                  ])
                )
              }
              className={styles.addColumnBtn}
            >
              <Icon className={styles.addIcon} as={FaPlus} />
              Add Column
            </Flex>
          )
        }
        actions={
          canWrite(user, RoleEntityType.DataTable, "") ? rowActions : undefined
        }
      />
    </>
  );
}

function CreateButtons({
  isCreate,
  dataTableId,
  saveDataTableMutation,
  connectionId,
  name,
  description,
  columns,
  outputType,
  outputPath,
  newUploadFile,
  newUploadKey,
  navigate,
  existingDataTable,
  appendOnly,
  appendTime,
  persistFileName,
  outputMode,
  toast,
}: {
  isCreate: boolean;
  dataTableId: string | null;
  saveDataTableMutation: any;
  connectionId: string;
  name: string;
  description: string;
  columns: DataTableColumn[];
  outputType: OutputType;
  outputPath: string;
  newUploadFile: File | null;
  newUploadKey: string | null;
  navigate: (path: string) => void;
  existingDataTable: DataTable | null;
  appendOnly: boolean;
  appendTime: boolean;
  persistFileName: boolean;
  outputMode: OutputMode;
  toast: CreateToastFnReturn;
}): ReactNode {
  const [isLoading, setIsLoading] = useState(false);
  const uploadService = useUploadService((state) => state);

  let colCopy = JSON.parse(JSON.stringify(columns));
  const table = {
    dataTableId: dataTableId || uuidv4(),
    name: name,
    description: description,
    outputType: outputType,
    outputPath: outputPath,
    connectionId: connectionId,
    columns: colCopy.map((col: DataTableColumn) => {
      if (appendOnly) col.isKey = false;
      col.aliases = col.aliases.reduce((list, alias) => {
        const a = alias.trim();
        if (a !== "") {
          list.push(a);
        }
        return list;
      }, [] as any);
      return col;
    }),
    createdAtUtc: existingDataTable?.createdAtUtc || new Date(),
    updatedAtUtc: new Date(),
    latestUploadKey: existingDataTable?.latestUploadKey,
    templateKey: existingDataTable?.templateKey,
    appendOnly: appendOnly,
    appendTime: appendTime,
    persistFileName: persistFileName,
    outputMode: outputMode,
  };

  let primaryBtnText = "Update";
  if (isCreate) {
    primaryBtnText = newUploadKey != null ? "Create & upload" : "Create";
  }

  return (
    <Flex className={styles.createUpdateBtnContainer}>
      <Button
        colorScheme="primaryScheme"
        color="white"
        isLoading={isLoading || saveDataTableMutation.isLoading}
        disabled={name === ""}
        onClick={async () => {
          const saved = await saveDataTableMutation.mutateAsync(table);
          if (newUploadKey != null) {
            setIsLoading(true);
            try {
              if (
                (await createUpload(
                  saved,
                  newUploadFile,
                  newUploadKey,
                  DataTableUploadMode.APPEND,
                  toast,
                  uploadService
                )) == null
              ) {
                return;
              }
            } finally {
              setIsLoading(false);
            }
          }
          navigate(`/datasources/${saved.dataTableId}`);
        }}
      >
        {primaryBtnText}
      </Button>
      {newUploadKey != null && (
        <Button
          variant="outline"
          colorScheme="primaryScheme"
          className={styles.createBtn}
          disabled={name === ""}
          isLoading={isLoading || saveDataTableMutation.isLoading}
          onClick={async () => {
            const saved = await saveDataTableMutation.mutateAsync(table);
            navigate(`/datasources/${saved.dataTableId}`);
          }}
        >
          Create empty
        </Button>
      )}
    </Flex>
  );
}

function renderConfigureDataStructure(
  user: UserWithRoles | null,
  isCreate: boolean,
  createFromUpload: boolean,
  loadingFile: boolean,
  newUploadKey: string | null,
  newUploadFileRows: any[],
  columns: DataTableColumn[],
  appendOnly: boolean,
  scriptEditorColumn: DataTableColumn | null,
  setColumns: (cols: DataTableColumn[]) => void,
  setNewUploadFile: (f: File) => void,
  setLoadingFile: (b: boolean) => void,
  setNewUploadFileRows: (rows: any[]) => void,
  setNewUploadKey: (k: string | null) => void,
  setNewUploadFileColumnTypes: (types: { [key: string]: DataType }) => void,
  setCreateFromUpload: (b: boolean) => void,
  setScriptEditorColumn: (c: DataTableColumn | null) => void,
  onUploadFail: () => void
) {
  return (
    <>
      <Heading className={styles.subHeading} size="md">
        Data Structure
      </Heading>
      {isCreate && (
        <Flex>
          <Flex
            onClick={() => {
              setColumns([]);
              setNewUploadKey(null);
              setCreateFromUpload(true);
            }}
            className={classNames(
              styles.createFromSelector,
              createFromUpload ? styles.createFromSelectorActive : null
            )}
          >
            Create from upload
          </Flex>
          <Flex
            onClick={() => {
              setColumns([]);
              setNewUploadKey(null);
              setCreateFromUpload(false);
            }}
            className={classNames(
              styles.createFromSelector,
              !createFromUpload ? styles.createFromSelectorActive : null
            )}
          >
            Create from scratch
          </Flex>
        </Flex>
      )}
      <Flex className={styles.createFromContainer}>
        {loadingFile ? (
          <Flex className={styles.spinnerContainer}>
            <Spinner
              colorScheme="primaryScheme"
              size="xl"
              label="Loading..."
              speed="0.6s"
              thickness="4px"
            />
          </Flex>
        ) : createFromUpload ? (
          renderCreateFromUpload(
            user || null,
            newUploadKey,
            newUploadFileRows,
            columns,
            appendOnly,
            scriptEditorColumn,
            setColumns,
            setNewUploadFile,
            setLoadingFile,
            setNewUploadFileRows,
            setNewUploadKey,
            setNewUploadFileColumnTypes,
            setScriptEditorColumn,
            onUploadFail
          )
        ) : (
          renderConfigureColumnsTable(
            user || null,
            columns,
            appendOnly,
            scriptEditorColumn,
            setColumns,
            null,
            setScriptEditorColumn
          )
        )}
      </Flex>
    </>
  );
}

function getOutputFileSuffix(outputType: string) {
  switch (outputType) {
    case OutputType.CSV:
      return ".csv";
    case OutputType.JSON:
      return ".json";
    default:
      return "";
  }
}

function MutateDataSource() {
  const { dataTableId } = useParams();
  const isCreate = dataTableId == null;

  const uploadService = useUploadService();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [columns, setColumns] = useState<DataTableColumn[]>([]);
  const [connectionId, setConnectionId] = useState("");
  const [outputPath, setOutputPath] = useState("");
  const [outputType, setOutputType] = useState<OutputType>(OutputType.CSV);
  const [dateOutputFormat, setDateOutputFormat] = useState("MM/dd/yyyy");
  const [dateTimeOutputFormat, setDateTimeOutputFormat] = useState(
    "MM/dd/yyyy HH:mm:ss.SSS"
  );
  const [loading, setLoading] = useState(
    dataTableId != null && dataTableId !== ""
  );
  const [appendOnly, setAppendOnly] = useState(false);
  const [appendTime, setAppendTime] = useState(false);
  const [persistFileName, setPersistFileName] = useState(false);
  const [outputMode, setOutputMode] = useState<OutputMode>(OutputMode.FULL);

  //TODO autocomplete
  const connections = useQuery("connections", () => getConnections(0, 1000));
  const hasConnections = (connections?.data?.results || []).length > 0;
  const [connection, setConnection] = useState<Connection | null>(null);
  const [isConnectionEmail, setIsConnectionEmail] = useState(false);

  const [createFromUpload, setCreateFromUpload] = useState(isCreate);
  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const [newUploadFile, setNewUploadFile] = useState<File | null>(null);
  const [newUploadFileColumnTypes, setNewUploadFileColumnTypes] = useState<{
    [key: string]: DataType;
  }>({});
  const [newUploadKey, setNewUploadKey] = useState<string | null>(null);
  const [newUploadFileRows, setNewUploadFileRows] = useState<any[]>([]);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [existingDataTable, setExistingDataTable] = useState<DataTable | null>(
    null
  );
  const [scriptEditorColumn, setScriptEditorColumn] =
    useState<DataTableColumn | null>(null);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const toast = useToast();
  const onUploadFail = toast.bind(null, {
    description:
      "Oops! It looks like you uploaded an incompatible file type. Please upload a file in either CSV (.csv) or Excel (.xlsx,.xls) format, and we'll be all set to go!",
    status: "error",
    duration: 3000,
    isClosable: true,
  });

  useEffect(
    () => {
      uploadService.setProcessingUpload(null);
      if (dataTableId) {
        // generateDataTableScript(dataTableId, "concat customer name and scn").then((res) => {
        //   console.log(res);
        // })
        getDataTable(dataTableId).then((dataTable) => {
          unstable_batchedUpdates(() => {
            setExistingDataTable(dataTable);
            setName(dataTable?.name || name);
            setDateOutputFormat(
              dataTable?.dateOutputFormat || dateOutputFormat
            );
            setDateTimeOutputFormat(
              dataTable?.dateTimeOutputFormat || dateTimeOutputFormat
            );
            setDescription(dataTable?.description || description);
            setColumns(
              dataTable?.columns
                ? dataTable.columns.sort((a, b) => a.index - b.index)
                : columns
            );
            setConnectionId(dataTable?.connectionId || connectionId);
            setOutputPath(dataTable?.outputPath || outputPath);
            setOutputType(dataTable?.outputType || outputType);
            setAppendOnly(dataTable?.appendOnly || appendOnly);
            setAppendTime(dataTable?.appendTime || appendTime);
            setPersistFileName(dataTable?.persistFileName || persistFileName);
            setOutputMode(dataTable?.outputMode || outputMode);
            setLoading(false);
          });
        });
      }
    },
    /* eslint-disable */
    []
  );

  const deleteMutation = useMutation(
    (dataTableId: string) => {
      return deleteDataTable(dataTableId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("dataTables");
        navigate("/datasources");
      },
    }
  );

  const saveDataTableMutation = useSaveDataTableMutation(
    dataTableId || null,
    (table: DataTable | null) => {
      queryClient.invalidateQueries("dataTables");
    }
  );

  if (connectionId === "" && hasConnections) {
    setConnectionId(connections.data!!.results[0].connectionId);
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  if (connectionId !== "" && hasConnections) {
    const foundConnection =
      connections.data?.results.find(
        (conn) => conn.connectionId === connectionId
      ) || null;
    if (
      foundConnection &&
      foundConnection.connectionId != connection?.connectionId
    ) {
      unstable_batchedUpdates(() => {
        setConnection(foundConnection);
        setIsConnectionEmail(foundConnection.connectionType === "EMAIL");
      });
    }
  }

  if (loading) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.mutateContainer}>
      <BreadcrumbBar
        list={
          isCreate
            ? [
                { name: "Data sources", to: "/datasources" },
                { name: "Create data source" },
              ]
            : [
                { name: "Data sources", to: "/datasources" },
                { name, to: `/datasources/${dataTableId}` },
                { name: "Edit" },
              ]
        }
      />

      <Heading className={styles.title}>
        {dataTableId ? "Update data source" : "Create data source"}
      </Heading>
      <FormControl className={styles.form}>
        <FormLabel className={styles.label} htmlFor="name">
          Name
        </FormLabel>
        <Input
          id="name"
          className={styles.input}
          placeholder="Name"
          value={name}
          required={true}
          onChange={(e) => {
            const nameBefore = name;
            const suffix = getOutputFileSuffix(outputType);
            setName(e.target.value);
            if (outputPath === "" || outputPath === `${nameBefore}${suffix}`) {
              setOutputPath(`${e.target.value.replaceAll(" ", "_")}${suffix}`);
            }
          }}
        />
        <FormLabel className={styles.label} htmlFor="description">
          Description
        </FormLabel>
        <Input
          id="description"
          className={styles.input}
          placeholder="Description"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
        <FormLabel className={styles.label} htmlFor="connection">
          Connection
        </FormLabel>
        <Select
          id="connection"
          className={styles.input}
          disabled={!hasConnections}
          value={connectionId}
          required={true}
          onChange={(e) => setConnectionId(e.currentTarget.value)}
        >
          {(connections?.data?.results || []).map((conn) => (
            <option key={conn.connectionId} value={conn.connectionId}>
              {conn.name}
            </option>
          ))}
        </Select>

        <FormLabel className={styles.label} htmlFor="appendOnly">
          Append Only
        </FormLabel>
        <Checkbox
          id="appendOnly"
          className={styles.checkboxInput}
          onChange={(e) => {
            setAppendOnly(e.target.checked);
          }}
          isChecked={appendOnly}
        >
          Will not allow any overwrite uploads
        </Checkbox>
        <FormLabel className={styles.label} htmlFor="outputMode">
          Output Mode
        </FormLabel>
        <Select
          id="outputMode"
          className={styles.input}
          value={outputMode}
          required={true}
          onChange={(e) => {
            setOutputMode(e.target.value as OutputMode);
            if (e.target.value === OutputMode.DELTA) {
              setAppendTime(true);
            }
          }}
        >
          <option key={OutputMode.FULL} value={OutputMode.FULL}>
            FULL
          </option>
          <option key={OutputMode.DELTA} value={OutputMode.DELTA}>
            DELTA
          </option>
        </Select>

        <FormLabel className={styles.label} htmlFor="outputType">
          Output Type
        </FormLabel>
        <Select
          id="outputType"
          className={styles.input}
          value={outputType}
          required={true}
          onChange={(e) => {
            const prevSuffix = getOutputFileSuffix(outputType);
            setOutputType(e.target.value as OutputType);
            const suffix = getOutputFileSuffix(e.target.value);
            if (prevSuffix !== suffix) {
              setOutputPath(outputPath.replace(prevSuffix, suffix));
            }
          }}
        >
          <option key={OutputType.CSV} value={OutputType.CSV}>
            CSV
          </option>
          <option key={OutputType.JSON} value={OutputType.JSON}>
            JSON
          </option>
        </Select>

        <FormLabel className={styles.label} htmlFor="outputPath">
          Output Path
        </FormLabel>
        <Input
          id="outputPath"
          className={styles.input}
          placeholder="Output Path"
          value={outputPath}
          required={true}
          onChange={(e) => setOutputPath(e.target.value)}
        />
        <FormLabel className={styles.label} htmlFor="dateOutputFormat">
          Output Date Format
        </FormLabel>
        <Input
          id="dateOutputFormat"
          className={styles.input}
          placeholder="Output Date Format"
          value={dateOutputFormat}
          required={true}
          onChange={(e) => setDateOutputFormat(e.target.value)}
        />
        <FormLabel className={styles.label} htmlFor="dateTimeOutputFormat">
          Output Date Time Format
        </FormLabel>
        <Input
          id="dateTimeOutputFormat"
          className={styles.input}
          placeholder="Output Date Time Format"
          value={dateTimeOutputFormat}
          required={true}
          onChange={(e) => setDateTimeOutputFormat(e.target.value)}
        />
        <Checkbox
          mt={2}
          id="appendTime"
          className={styles.checkboxInput}
          onChange={(e) => {
            setAppendTime(e.target.checked);
          }}
          isChecked={appendTime}
        >
          Append timestamp to the file name when uploaded
        </Checkbox>
        <Checkbox
          mt={2}
          id="persistFileName"
          className={styles.checkboxInput}
          onChange={(e) => {
            setPersistFileName(e.target.checked);
          }}
          isChecked={persistFileName}
        >
          Maintain file name when writing to output connection (will be
          prepended with output path if set)
        </Checkbox>

        {renderConfigureDataStructure(
          user || null,
          isCreate,
          createFromUpload,
          loadingFile,
          newUploadKey,
          newUploadFileRows,
          columns,
          appendOnly,
          scriptEditorColumn,
          setColumns,
          setNewUploadFile,
          setLoadingFile,
          setNewUploadFileRows,
          setNewUploadKey,
          setNewUploadFileColumnTypes,
          setCreateFromUpload,
          setScriptEditorColumn,
          onUploadFail
        )}
        {!loadingFile && (
          <Flex className={styles.btnFlexContainer}>
            {canWrite(user, RoleEntityType.DataTable, dataTableId || "") && (
              <CreateButtons
                isCreate={isCreate}
                dataTableId={dataTableId || null}
                saveDataTableMutation={saveDataTableMutation}
                connectionId={connectionId}
                name={name}
                description={description}
                columns={columns}
                outputType={outputType}
                outputPath={outputPath}
                newUploadFile={newUploadFile}
                newUploadKey={newUploadKey}
                navigate={navigate}
                existingDataTable={existingDataTable}
                appendOnly={appendOnly}
                appendTime={appendTime}
                persistFileName={persistFileName}
                outputMode={outputMode}
                toast={toast}
              />
            )}

            {!isCreate && dataTableId && (
              <DeleteSimpleDialog
                title="Delete data source"
                body="Are you sure you want to delete this data source?"
                canDelete={canDelete(
                  user,
                  RoleEntityType.DataTable,
                  dataTableId || ""
                )}
                isLoading={deleteMutation.isLoading}
                onDelete={() => deleteMutation.mutate(dataTableId)}
              />
            )}
          </Flex>
        )}
      </FormControl>
    </Flex>
  );
}

export default MutateDataSource;
